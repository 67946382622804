import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { 
      path: '*', 
      redirect: '/404' 
    },
    {
      path: '/status/:_sub_id/:_key',
      alias: '/:_id/status/:_sub_id/:_key',
      name: 'status',
      component: () => import("./views/Status.vue"),
    },      
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
    }
  ],
});

export default router;